// @ts-nocheck

const LOCATIONS = ['bottom', 'right']
const INPUTTYPES = ['formInput', 'special']
const DATATYPES = ['text', 'audio', 'image', 'video', 'number', 'images', 'file']

export const taskTypes: any = {
  'type-a': {
    types: [
      'no-answer'
    ],
    configs: {
      submitButtonText: 'Mark as completed',
      submitButtonPosition: LOCATIONS[0],
      inputType: null,
      showAnswer: false,
      resultsDelay: false,
      showProgressBar: false,
      dataType: null
    }
  },
  'type-b': {
    types: [
      'image', 'judged-image', 'judged-image-ai', 'image-share', 'image-similarity', 'object-recognition'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: true,
      dataType: DATATYPES[2]
    }
  },
  'type-c': {
    types: [
      'video', 'judged-video'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: true,
      dataType: DATATYPES[3]
    }
  },
  'type-d': {
    types: [
      'audio', 'judged-audio'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: true,
      dataType: DATATYPES[1]
    }
  },
  'type-e': {
    types: [
      'fill-in-the-blanks', 'scrambled-phrase', 'combination-lock'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[1],
      showAnswer: true,
      resultsDelay: true,
      showProgressBar: false,
      dataType: DATATYPES[0]
    }
  },
  'type-f': {
    types: [
      'text', 'text-ai', 'free-text', 'text-share', 'judged-text'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[1],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: true,
      showProgressBar: false,
      dataType: DATATYPES[0]
    }
  },
  'type-g': {
    types: [
      'word-search'
    ],
    configs: {
      submitButtonPosition: null,
      inputType: INPUTTYPES[1],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: false,
      dataType: null
    }
  },
  'type-h': {
    types: [
      'qrbarcode'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[1],
      inputType: INPUTTYPES[0],
      showAnswer: false,
      resultsDelay: true,
      showProgressBar: false,
      dataType: DATATYPES[4]
    }
  },
  'type-i': {
    types: [
      'location'
    ],
    configs: {
      submitButtonText: 'Submit current location',
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: false,
      dataType: null
    }
  },
  'type-j': {
    types: [
      'direction'
    ],
    configs: {
      submitButtonText: 'Mark as arrived',
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: false,
      dataType: null
    }
  },
  'type-k': {
    types: [
      'multiple-choice'
    ],
    configs: {
      submitButtonPosition: null,
      inputType: null,
      showAnswer: true,
      resultsDelay: true,
      showProgressBar: false,
      dataType: null
    }
  },
  'type-m': {
    types: [
      'audio-words-recognition'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: true,
      dataType: DATATYPES[1]
    }
  },
  'type-n': {
    types: [
      'number', 'integer'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[1],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: true,
      showProgressBar: false,
      dataType: DATATYPES[4]
    }
  },
  'type-o': {
    types: [
      'image-jigsaw'
    ],
    configs: {
      submitButtonPosition: null,
      inputType: INPUTTYPES[1],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: false,
      dataType: null
    }
  },
  'type-p': {
    types: [
      'matching-pairs'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[1],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: false,
      dataType: null
    }
  },
  'type-q': {
    types: [
      'image-multiple'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[1],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: false,
      dataType: DATATYPES[5]
    }
  },
  'type-r': {
    types: [
      'judged-file', 'file'
    ],
    configs: {
      submitButtonPosition: LOCATIONS[0],
      inputType: INPUTTYPES[0],
      showAnswer: true,
      resultsDelay: false,
      showProgressBar: true,
      dataType: DATATYPES[6]
    }
  },
}

export const getTaskTypeConfig = (taskType: string, configName: string) => {
  for (const [key, value] of Object.entries(taskTypes)) {
    const configs = value.configs
    const types = value.types

    if (types.includes(taskType)){
      for (const [key1, value1] of Object.entries(configs)) {
        if (key1 === configName) {
          return value1
        }
      }
    }
  }

  return null
}
